.codeblock {
    

    margin : 10px;
    margin-bottom: 20px;
    display: flex;
    max-width: 100%;

    flex-direction: column;
    background-color:#f3f3f3;


}

.codeblock > SyntaxHighlighter {
    padding: 5px;
}

.codeblock > h3 {
    padding-top: 10px;
    padding-left: 10px;
}