/* Filename: App.css */
html{
  scroll-behavior: smooth;
}
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
  font-family: 'Open Sans',sans-serif,arial,verdana;
  line-height: 125%;
  
}
.main{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.codeblock {
  max-width: 50%;
}

.support{
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 20px;
	font-size: 1rem;
  
}

/* styles.css */
.custom-table {

  border-collapse: collapse;
  margin-right: 20px;
  margin-left: 13px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-table th, .custom-table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 12px;

}

.custom-table th {
  background-color: #f4f4f4;
  color: #333;
  font-weight: bold; /* Added for bold headings */
}



/* .custom-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
} */

.custom-table tbody tr:hover {
  background-color: #e0e0e0;
}


img {
  width: 96px;
  margin-left: 10px;
  
}
.container img {
  width: 50%;
  margin: 15px;
  display: block;

}

.sidebar-data-wrapper{
  display: flex;

  flex-direction: row;
  flex: 1;
}

.sidebar-wrapper{
 margin: 20px;
 background-color:#eee;
  font-size: medium;


}

.navbar-2{
  padding-left: 0.75rem;
  background-color: #eee;
}
.nav{
  background-color: gray;
 
}


.container{
  padding : 10px;
  margin: 10px 20px;
}

.container > section {
  margin-top: 20px;
}

ul > li {
  padding-left: 25px;
}
ol > li {
  padding-left: 25px;
}

a{
  color : #0abe51;
  text-decoration: none;
}

li,p,iframe{
  margin-bottom: 10px;
}

h1,h2{
  margin-bottom: 20px;
  margin-top: 20px;
}


/* ul ul {
  
  padding: 8px;
}
ul ul li {

  padding: 6px;
  margin-bottom: 6px;
}
ul ol {
  
  padding: 8px;
}
ol ul{
  padding: 8px;
} */
h1{
  font-weight: 450;
}
h2{
  font-weight: 400;
}

td > ol > li {
  padding-left: 50px;
  list-style-type: none;
  margin-top: 10px;
}
td > ul > li {
  padding-left: 50px;
  list-style-type: none;
  margin-top: 10px;
}

/* .dot{
  height : 5px;
  width : 5px;
  border-radius: 100%;
  background-color: black;
} */


.caution{
  background-color: rgb(239, 223, 223);
  padding : 20px;
}

a:active,a:hover{
  color:#0abe51;
}



.bold{
  font-weight: 650;;
}


ul {
  margin: 0.50em 0;
  padding: 0 0.5em;
  list-style-position: inside; /* Move the bullet inside the list item */
  


}


ul li {
  margin-bottom: 5px; /* Adjust the margin between list items */
}

ol > li {
  margin-top: 2px;
}
/* 
li{

  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0px !important;
  text-align: start !important;
  text-align-last: start !important;
} */





